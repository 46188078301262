.progress-ring__circle {
    transition: stroke-dashoffset 0.35s;
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
}

.cont {
    position: relative;
}
/* opacity pulse animation */
@keyframes pulse {
    0% {
        opacity: 0.8;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0.8;
    }
}

.calculating {
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #494949;
    position: relative;
    right: 35px;
    animation: pulse 2s infinite ease-in-out;
}
